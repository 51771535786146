<template>
    <div>
        <page-header>
            <template slot="tools">
                <button
                    class="inline-block btn-primary" 
                    @click="openEditModal(null)"
                >
                    Add role
                </button>
            </template>
        </page-header>

        <card class="mt-6 bg-transparent" bodyClass="p-0 overflow-visible -mx-6">
            <v-table
                class="admin-table"
                :id="'roles'"
                :columns="columns"
                row-key="id"
                ref="roles"
                :endpoint="$apiUrl.roles.base"
                sort-key="created_at"
                no-cache
                :loading="loadingRoles"
            >
                <template slot="cell" slot-scope="{row, col}">
                    <span v-if="col.key === 'permissions'">
                        {{ row[col.key].length }}
                    </span>
                    <span v-else-if="['updated_at', 'created_at'].includes(col.key)">
                        {{ formatDate(row[col.key]) }}
                    </span>
                    <div v-else-if="col.key === 'action' && !hiddenActionsRoles.includes(row.name.toLowerCase())">
                        <div class="flex flex__justify-end">
                            <button class="ml-4" @click="openEditModal(row)">
                                <svg-importer icon-name="icons/edit" :key="`role_${row.id}_edit`" />
                            </button>

                            <button class="ml-4" @click="openDeleteModal({ id: row.id, name: row.name })">
                                <svg-importer icon-name="icons/close" :key="`role_${row.id}_close`" />
                            </button>
                        </div>
                    </div>
                    <span v-else>
                        {{ row[col.key] }}
                    </span>
                </template>
            </v-table>
        </card>
        
        <delete-modal
            name="Role"
            :show="isShowDeleteModal"
            :selectedObject="selectedRole"
            @hide="closeDeleteModal"
            @delete="deleteRole"
        ></delete-modal>

        <modal-edit :show="isShowEditModal" :role="selectedRole" @hide="closeEditModal" @reload="updateRoles" />
    </div>
</template>
<script>
import axios from 'axios';
import moment from 'moment';

import ModalEdit from './ModalEdit.vue';
import SvgImporter from '../../../components/SvgImporter.vue';

const columns = [
    {
        title: "ID",
        key: "id",
        toggled: true,
        sortable: true,
        width: "w-24"
    },
    {
        title: "Name",
        key: "name",
        toggled: true,
        sortable: true,
        width: "w-24"
    },
    {
        title: "Permissions",
        key: "permissions",
        toggled: true,
        sortable: true,
        width: "w-24"
    },
    {
        title: "Updated at",
        key: "updated_at",
        toggled: true,
        sortable: true,
        width: "w-24"
    },
    {
        title: "Created at",
        key: "created_at",
        toggled: true,
        sortable: true,
        width: "w-24"
    },
    {
        title: "",
        key: "action",
        toggled: true,
        sortable: false,
        width: "w-12"
    },
];

export default {
    components: {
        ModalEdit,
        SvgImporter,
    },
    data() {
        return {
            hiddenActionsRoles: ['god users', 'organization admin', 'supply chain coordinator'],
            loadingRoles: false,
            roles: [],
            columns,
            isShowDeleteModal: false,
            isShowEditModal: false,
            selectedRole: null,
        }
    },
    async created() {
        this.updateRoles();
    },
    methods: {
        async updateRoles() {
            const { data : { data } } = await axios.get(this.$apiUrl.roles.base);
        
            this.roles = data;
            this.$refs.roles.table.fetchRows();
        },
        formatDate(date) {
            return date ? moment(date).format('DD-MM-YYYY HH:mm') : '-';
        },
        openDeleteModal(role) {
            this.selectedRole = role;
            this.isShowDeleteModal = true;
        },
        closeDeleteModal() {
            this.isShowDeleteModal = false;
            this.selectedRole = null;
        },
        openEditModal(role) {
            this.isShowEditModal = true;
            this.selectedRole = role
                ? {
                    id: role.id,
                    name: role.name,
                    permissions: role.permissions.map(permission => ({ id: permission.id, name: permission.name }))
                }
                : null;
        },
        clearSelectedRole() {
            this.selectedRole = null;
        },
        closeEditModal() {
            this.isShowEditModal = false;
            this.selectedRole = null;
        },
        async deleteRole() {
            try {
                await axios.delete(`${this.$apiUrl.roles.base}/${this.selectedRole.id}`);

                this.$snotify.success(`Role ${this.selectedRole.name} removed`);
            } catch (error) {
                this.$snotify.error(`Failed to remove role ${this.selectedRole.name}`);
            }
            
            this.updateRoles();
            this.closeDeleteModal();
        }
    }
}
</script>